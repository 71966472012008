import React from 'react'
import Helmet from 'react-helmet';
import { StaticQuery, graphql, Link } from 'gatsby'
import get from 'lodash/get'
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import Layout from 'components/layout.js'
import MainNavigation from 'components/navigations/main-navigation/main-navigation.js'
import GlobalContext from 'components/stores/global-context';
import ThemeHelper from 'components/helpers/theme-helper.js';
import styles from './404.module.less'
import FooterNavigation from 'components/navigations/footer-navigation/footer-navigation';

export default class Four0FourTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.pageTitle = 'Mcm - Sidan kan inte hittas'
    this.navigationFooter = get(this.siteConfig, 'defaultFooter');
    ThemeHelper.setTheme('company');
  }

  render() {
    return (
            <div className={"theme-" + ThemeHelper.getTheme()}>
            <Layout>
              <Helmet
                title={this.pageTitle}
                meta={[
                  { name: 'theme-color', content: '#000000' },
                  { name: 'robots', content: "noindex,follow" },
                  { property: 'og:title', content: this.pageTitle },
                  { property: 'og:type', content: 'website' }
                ]}
                link={[
                  { rel: 'manifest', href : '/manifest.webmanifest'},
                  { rel: 'icon', type: 'image/x-icon',href: '/favicon.ico' },
                  { rel: 'icon', sizes: '192x192', href: '/icons/icon-192x192.png' }
                ]}
              />
              <h1 className="visuallyHidden">{this.pageTitle}</h1>
              <StaticQuery
                query={staticQuery}
                render={data => {
                  this.siteConfig = data.contentfulSiteConfiguration

                  const background = get(this.siteConfig, 'four0fourBackground');
                  const backgroundPatternUrl = get(background, 'backgroundPattern.file.url');

                  return this.siteConfig ? (
                    <>
                      <MainNavigation
                        contentFooter={data.contentfulFooter}
                        data={data.contentfulMainNavigation}
                        siteConfig={this.props.siteConfig}
                        contentPage={{ id: ''}}
                      />
                      <main id={'Main'} className={'blocks'}>
                        <section style={{backgroundImage: `url(${backgroundPatternUrl})`}} className={['block block--no-margin', styles.four0four__content].join(' ')}>
                        <div className={``}>
                        <div className={['block--max-width', styles.four0four].join(' ')}>
                              <div className={styles.four0four__content}>
                                <span className={[styles.four0four__icon, this.siteConfig.four0fourIcon].join(' ')} />
                                <h2 className={["h1", styles.four0four__title].join(' ')}>{this.siteConfig.four0fourTitle}</h2>
                                {this.siteConfig.four0fourDescription && this.siteConfig.four0fourDescription.childMarkdownRemark &&
                                  <div dangerouslySetInnerHTML={{__html: this.siteConfig.four0fourDescription.childMarkdownRemark.html}} className={styles.four0four__description} />
                                }
                                {this.siteConfig.four0fourLink &&
                                <UiBtn
                                  label={this.siteConfig.four0fourLinkLabel}
                                  className={['ui-btn--medium ui-btn--primary ui-btn--text-center', styles.four0four__btn].join(' ')}
                                  element={'url'}
                                  routerLink={Link}
                                  url={this.siteConfig.four0fourLink.slug}>
                                </UiBtn>}
                              </div>
                            </div>
                          </div>
                          </section>
                      </main>
                      {this.navigationFooter && <FooterNavigation data={this.navigationFooter} siteSettings={this.siteConfig} />}
                    </>
                  ) : <h2 className={["h1", styles.four0four__title].join(' ')}>{this.pageTitle}</h2>
                }}
              />
            </Layout>
          </div>
    )
  }
}
Four0FourTemplate.contextType = GlobalContext;

const staticQuery = graphql`
  query Four0FourQuery {
    contentfulMainNavigation {
      ...MainNavigation
    }
    contentfulSiteConfiguration{
      ...SiteConfiguration
    }
  }
`