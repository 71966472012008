import { Link } from 'gatsby';
import React from 'react';

const LinkWrapper = props => {
  const { link, className, children } = props;
  const isExternalUrl = link ? /^((http|https):\/\/)/.test(link) : false;

  return link ? (
    isExternalUrl ?
      <a href={link} className={className} key={props.id}>
        {children}
      </a>
    :
      <Link to={link} className={className} key={props.id}>
        {children}
      </Link>
  ) : (
    <div className={className} key={props.id}>{children}</div>
  );
};

export default LinkWrapper;
